import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import link from "../../assets/img/Icons/link.svg";
const ResultGeneralInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="result-general-info">
      <div className="result-container">
        <h2 className="title">{t("result_general_info.caption")}</h2>
        <p className="text primary">
        {t("result_general_info.sub_caption")}
        </p>
        <p className="text secondary">
          {t("result_general_info.sub_text")}
        </p>
        <Link to="https://videodoktor.az/app" className="videodoktor-directory" target="_blank">
        {t("result_general_info.btn_text")}{" "}
          <span className="icon">
            <img src={link} alt="icon" />
          </span>{" "}
        </Link>
      </div>
    </div>
  );
};

export default ResultGeneralInfo;
