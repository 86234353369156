import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { Auth } from "../utils/Auth";

// Components
import Button from "./Button";

// Img/Icon
import LogoBase from "../assets/img/Icons/logo-base.svg";
import logoWhite from "../assets/img/Icons/logo-white.svg";
import Logo from "../assets/img/Icons/logo.svg";
import profile from "../assets/img/Icons/pofile.svg";
import profileWhite from "../assets/img/Icons/profile-white.svg";
import { MainContext } from "../utils/MainContext";
import Login from "../page/Login";

const Header = () => {
  // Glboal State
  const { authToken, setLoginVisible, loginVisible } = useContext(Auth);
  const { setProfileActivePage, resetStates } = useContext(MainContext);

  // Local State
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [langActive, setLangActive] = useState(false);

  // Language
  const { i18n, t } = useTranslation();

  // React Router Dom
  const location = useLocation();

  // Change Language
  const selectLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const positionStyle = {
    position: "fixed",
    top: 0,
    left: "0",
  };

  useEffect(() => {
    if (location.pathname === "/") {
      const handleScroll = () => {
        setIsScrolled(window.scrollY >= 200);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location]);

  // Detect device width for render box component
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header
        className={
          location.pathname === "/" ||
          location.pathname === "/login" ||
          location.pathname === "/forgot-password" ||
          location.pathname === "/sign-up" ||
          location.pathname === "/profile" ||
          location.pathname === "/history-detail"
            ? "header "
            : location.pathname === "/check-up"
            ? "header checkup-header"
            : location.pathname !== "/" && location.pathname !== "check-up"
            ? "header nonVisible"
            : null
        }
        style={
          location.pathname === "/" && isScrolled
            ? positionStyle
            : { position: "static" }
        }
      >
        <div className="container">
          <div className="row">
            <Link to="/" className="logo" onClick={() => resetStates()}>
              <img
                src={
                  location.pathname === "/" ||
                  location.pathname === "/login" ||
                  location.pathname === "/sign-up" ||
                  location.pathname === "/forgot-password" ||
                  location.pathname === "/profile"
                  ||
          location.pathname === "/history-detail"
                    ? windowWidth < 578
                      ? LogoBase
                      : Logo
                    : logoWhite
                }
                alt="logo"
              />
            </Link>
            <div className="additional-area">
              <div
                className="lang-area"
                onClick={() => {
                  setLangActive(!langActive);
                }}
              >
                <span className="lang-text">{i18n.language}</span>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1.25L6.29289 6.04289C6.68342 6.43342 7.31658 6.43342 7.70711 6.04289L12.5 1.25"
                    stroke="#334155"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
                <ul className={langActive ? "lang-list active" : "lang-list"}>
                  <li
                    className="lang-item"
                    onClick={() => {
                      selectLanguage("az");
                      setLangActive(false);
                    }}
                  >
                    Azerbaijan
                  </li>
                  <li
                    className="lang-item"
                    onClick={() => {
                      selectLanguage("en");
                      setLangActive(false);
                    }}
                  >
                    Ingilis
                  </li>
                </ul>
              </div>
              {location.pathname !== "/login" &&
                location.pathname !== "/sign-up" &&
                location.pathname !== "/forgot-password" &&
                !authToken && (
                  <button
                    className="btn active"
                    // style={{
                    //   flexDirection: image === "next" ? "row-reverse" : "row",
                    // }}
                    onClick={() => {
                      setLoginVisible(true);
                    }}
                  >
                    {t("header.login")}
                  </button>
                )}
              {authToken && (
                <Link
                  to="/profile"
                  className="profile-icon"
                  onClick={() => setProfileActivePage("Account")}
                >
                  {location.pathname !== "/check-up" ? (
                    <img src={profile} alt="profile" />
                  ) : (
                    <img src={profileWhite} alt="profile" />
                  )}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div
          className={langActive ? "overlay active" : "overlay"}
          onClick={() => {
            if (langActive) {
              setLangActive(false);
            }
          }}
        ></div>
      </header>
      {loginVisible && <Login />}
    </>
  );
};

export default Header;
