/* ---------------------------------- React --------------------------------- */
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/sass/main.scss";

/* -------------------------------- Component ------------------------------- */
import App from "./App";

/* ---------------------------- React Router Dom ---------------------------- */
import { HashRouter } from "react-router-dom";

// i18n next
import "./utils/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
