import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "../../utils/Auth";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../utils/MainContext";

import prev from "../../assets/img/Icons/Back.svg";
import next from "../../assets/img/Icons/Next.svg";
import arrow from "../../assets/img/Icons/learn-more.svg";
const History = () => {
  // Global State
  const { userId, checkupData, setCheckupData } = useContext(Auth);
  const { profileActivePage } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [maxPageCount, setMaxPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Translate
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  // Get Request Schem For History
  const getHistoryRequest = useCallback(
    async (pageNumber) => {
      setData(["loading"]);
      await axios
        .get(
          `https://api.medcheckup.az/api/user-data/user/${userId}/?page=${pageNumber}&page_size=6&lang=${i18n.language}`
        )
        .then((res) => {
          setCurrentPage(pageNumber);

          setData(res.data.results);

          let pageCount = Number(res.data.count) / 6;

          // Eğer tam sayı ise, olduğu gibi bırak
          if (pageCount === Math.floor(pageCount)) {
            return setMaxPageCount(pageCount);
          } else {
            // Eğer tam sayı değilse, en yakın üst tam sayıya yuvarla
            return setMaxPageCount(Math.ceil(pageCount));
          }
        })
        .catch((err) => err && navigate("/error"));
    },
    [i18n.language, navigate, userId]
  );

  useEffect(() => {
    if (i18n.language && userId && profileActivePage === "History") {
      getHistoryRequest(currentPage);
    }
  }, [
    currentPage,
    getHistoryRequest,
    i18n.language,
    profileActivePage,
    userId,
  ]);

  // Conver Checdkup Date
  function convertDateFormat(dateString) {
    const date = new Date(dateString); // ISO formatındaki tarih
    if (isNaN(date)) {
      return "Geçersiz tarih"; // Geçersiz tarih kontrolü
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  // Handle Click Function
  const handleClick = (checkup) => {
    setCheckupData(checkup.token);
    navigate("/history-detail");
  };

  return (
    <section className="profile-history">
      <ul className="history-list">
        {data[0] === "loading" ? (
          Array.from({ length: 6 }, (_, index) => {
            return (
              <div className="loader-line" key={index}>
                <div className="left-side"></div>
                <div className="right-side"></div>
              </div>
            );
          })
        ) : data.length !== 0 ? (
          data.map((item) => (
            <li className="history-item" key={item.id}>
              <div className="left-side">
                <h2 className="title">{t("general.checkup")}</h2>
                <h3 className="subTitle">{item.checkup_for}</h3>
                <h4 className="date"> {convertDateFormat(item.created)}</h4>
              </div>
              <button onClick={() => handleClick(item)}>
                {t("result_symptom.learn_more")}
                <span className="icon">
                  <img src={arrow} alt="icon" />
                </span>
              </button>
            </li>
          ))
        ) : (
          <div className="empty">
            <p className="text">{t("general.empty_history")}</p>
          </div>
        )}
      </ul>
      <div className="pagination">
        <button
          className="prev"
          onClick={() =>
            currentPage !== 1 && getHistoryRequest(currentPage - 1)
          }
        >
          <img src={prev} alt="icon" />
        </button>
        <p className="info-page">
          {currentPage}
          {maxPageCount !== 0 && " - "}
          {maxPageCount !== 0 && maxPageCount}
        </p>
        <button
          className="next"
          onClick={() =>
            currentPage < maxPageCount && getHistoryRequest(currentPage + 1)
          }
        >
          <img src={next} alt="icon" />
        </button>
      </div>
    </section>
  );
};

export default History;
