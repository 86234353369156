import { useTranslation } from "react-i18next";
import HomeCaption from "./HomeCaption";
import { useState } from "react";
import prev from "../../assets/img/Icons/Back.svg";
import next from "../../assets/img/Icons/Next.svg";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const Impressions = () => {
  const [data, setData] = useState([]);
  const [maxPageCount, setMaxPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // Language
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const getFeedBAck = useCallback(
    async (pageNumber) => {
      setData(["loading"]);
      await axios
        .get(
          `https://api.medcheckup.az/api/reviews/?page=${pageNumber}&lang=${i18n.language}&page_size=4`
        )
        .then((res) => {
          setCurrentPage(pageNumber);

          setData(res.data.results);

          let pageCount = Number(res.data.count) / 4;

          // Eğer tam sayı ise, olduğu gibi bırak
          if (pageCount === Math.floor(pageCount)) {
            return setMaxPageCount(pageCount);
          } else {
            // Eğer tam sayı değilse, en yakın üst tam sayıya yuvarla
            return setMaxPageCount(Math.ceil(pageCount));
          }
        })
        .catch((err) => err && navigate("/error"));
    },
    [i18n.language, navigate]
  );

  useEffect(() => {
    getFeedBAck(1);
  }, [getFeedBAck]);

  console.log(data);

  return (
    <section className="impressions">
      <div className="container">
        <HomeCaption title={t("impressions.caption")} />
        <div className="feedback-area">
          {data[0] === "loading"
            ? Array.from({ length: 4 }, (_, index) => {
                return (
                  <div
                    className="feedback-card feedback-card-loader"
                    key={index}
                  >
                    <div className="top">
                      <div className="personal-name"></div>
                      <div className="star-area"></div>
                      <div className="comment"></div>
                    </div>
                  </div>
                );
              })
            : data.map((item) => (
                <div className="feedback-card" key={item.id}>
                  <div className="top">
                    <h5 className="personal-name">{item.full_name}</h5>
                    <div className="star-area">
                      {Array.from({ length: 5 }, (_, index) => {
                        return (
                          <div
                            className={
                              index + 1 > item.star ? "star " : "star active"
                            }
                            key={index}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.517 12.3231L3.027 18.7751C2.98086 18.9704 2.99471 19.1752 3.06675 19.3625C3.13878 19.5499 3.26564 19.7112 3.43077 19.8254C3.5959 19.9395 3.79161 20.0012 3.99235 20.0024C4.1931 20.0036 4.38953 19.9442 4.556 19.8321L10.001 16.2021L15.446 19.8321C15.6163 19.9452 15.8172 20.0034 16.0216 19.9989C16.226 19.9944 16.4242 19.9274 16.5894 19.807C16.7546 19.6865 16.879 19.5184 16.9458 19.3251C17.0126 19.1319 17.0186 18.9228 16.963 18.7261L15.134 12.3261L19.67 8.24406C19.8153 8.11325 19.919 7.94272 19.9684 7.75357C20.0178 7.56442 20.0107 7.36494 19.9479 7.1798C19.8851 6.99467 19.7695 6.83199 19.6152 6.71189C19.461 6.59179 19.2749 6.51953 19.08 6.50406L13.379 6.05006L10.912 0.589063C10.8333 0.413219 10.7055 0.263899 10.5438 0.159123C10.3822 0.0543482 10.1936 -0.00140381 10.001 -0.00140381C9.80836 -0.00140381 9.61984 0.0543482 9.45819 0.159123C9.29654 0.263899 9.16866 0.413219 9.09 0.589063L6.623 6.05006L0.922003 6.50306C0.730458 6.51824 0.547341 6.58827 0.394558 6.70479C0.241775 6.82131 0.125796 6.97938 0.060496 7.16009C-0.00480381 7.3408 -0.0166594 7.5365 0.0263478 7.72377C0.0693549 7.91104 0.165404 8.08195 0.303003 8.21606L4.517 12.3231ZM7.37 7.99706C7.5485 7.983 7.71992 7.92118 7.8663 7.81808C8.01269 7.71497 8.12864 7.57439 8.202 7.41106L10.001 3.43006L11.8 7.41106C11.8734 7.57439 11.9893 7.71497 12.1357 7.81808C12.2821 7.92118 12.4535 7.983 12.632 7.99706L16.604 8.31206L13.333 11.2561C13.049 11.5121 12.936 11.9061 13.04 12.2741L14.293 16.6591L10.557 14.1681C10.393 14.058 10.2 13.9992 10.0025 13.9992C9.80501 13.9992 9.61198 14.058 9.448 14.1681L5.544 16.7711L6.594 12.2251C6.6325 12.0579 6.62733 11.8836 6.579 11.7189C6.53066 11.5543 6.44078 11.4049 6.318 11.2851L3.28 8.32306L7.37 7.99706Z"
                                fill="#042735"
                              />
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                    <div className="comment">{item.comment}</div>
                  </div>
                </div>
              ))}
        </div>
        <div className="pagination">
          <button
            className="prev"
            onClick={() => currentPage !== 1 && getFeedBAck(currentPage - 1)}
          >
            <img src={prev} alt="icon" />
          </button>
          <p className="info-page">
            {currentPage}
            {maxPageCount !== 0 && " - "}
            {maxPageCount !== 0 && maxPageCount}
          </p>
          <button
            className="next"
            onClick={() =>
              currentPage < maxPageCount && getFeedBAck(currentPage + 1)
            }
          >
            <img src={next} alt="icon" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Impressions;
