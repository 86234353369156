// React
import { Route, Routes } from "react-router-dom";

// Private Router
import PrivateRoutes from "./router/PrivateRoutes";

// Contect
import { AppContext } from "./utils/MainContext";
import { Validation } from "./utils/Auth";

// Components
import Header from "./component/Header";
import Footer from "./component/Footer";

// Pages
import Home from "./page/Home";
import NotFoundPage from "./page/NotFoundPage";
import CheckUpPage from "./page/CheckUpPage";
import SignUp from "./page/SingUp";
import Login from "./page/Login";
import ForgetPassword from "./page/ForgetPassword";
import Profile from "./page/Profile";
import HistoryDetail from "./page/HistoryDetail";

const App = () => {
  return (
    <>
      <Validation>
        <AppContext>
        <Header />
          <Routes>
            
            {/* Home Page */}
            <Route path="/" element={<Home />} />

            {/* Checkup Page */}
            <Route path="/check-up" element={<CheckUpPage />} />

            {/* Auth */}
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />

            {/* Profile */}
            <Route element={<PrivateRoutes />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/history-detail" element={<HistoryDetail />} />

            </Route>
            {/* 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        <Footer />
        </AppContext>
      </Validation>
    </>
  );
};

export default App;
