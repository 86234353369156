// React
import { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Components
import Hero from "./home-section/Hero";
import MedCheckupInfo from "./home-section/MedCheckupInfo";
import Reference from "./home-section/Reference";
import Impressions from "./home-section/Impressions";
import WebsiteDescription from "./home-section/WebsiteDescription";
import Loader from "../component/Loader";

// Axios
import axios from "axios";

const Home = () => {
  //Translate
  const { i18n } = useTranslation();

  // Local State
  const [loading, setLoading] = useState(false);
  const [heroData, setHeroData] = useState(null);
  const [smptomCheckerData, setSmptomCheckerData] = useState(null);
  const [smptomCheckerCards, setSmptomCheckerCards] = useState([]);
  const [referanceData, setReferanceData] = useState(null);
  const [referanceCards, setReferanceCards] = useState([]);
  const [videoData, setVideoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          heroRes,
          smptomCheckerRes,
          serviceCardRes,
          referanceRes,
          referanceCardRes,
          videoRes,
        ] = await Promise.all([
          axios.get(
            `https://api.medcheckup.az/api/home/main/?lang=${i18n.language}`
          ),
          axios.get(
            `https://api.medcheckup.az/api/home/symptom-checker/?lang=${i18n.language}`
          ),
          axios.get(
            `https://api.medcheckup.az/api/home/service-cards/?lang=${i18n.language}`
          ),
          axios.get(
            `https://api.medcheckup.az/api/home/best-references/?lang=${i18n.language}`
          ),
          axios.get(
            `https://api.medcheckup.az/api/home/reference-cards/?lang=${i18n.language}`
          ),
          axios.get(
            `https://api.medcheckup.az/api/home/activity/?lang=${i18n.language}`
          ),
        ]);
        setHeroData(heroRes.data.results[0]);
        setSmptomCheckerData(smptomCheckerRes.data.results[0]);
        setSmptomCheckerCards(serviceCardRes.data.results);
        setReferanceData(referanceRes.data.results[0]);
        setReferanceCards(referanceCardRes.data.results);
        setVideoData(videoRes.data.results[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [i18n.language]);

  // console.log(heroData);
  // console.log(referanceData);

  return (
    <main>
      {loading ? (
        <Loader />
      ) : (
        <>
          {heroData && <Hero heroData={heroData} />}
          {smptomCheckerData && (
            <MedCheckupInfo
              smptomCheckerData={smptomCheckerData}
              smptomCheckerCards={smptomCheckerCards}
            />
          )}

          {referanceData && (
            <Reference
              referanceData={referanceData}
              referanceCards={referanceCards}
            />
          )}
          <Impressions />
          {videoData && <WebsiteDescription videoData={videoData} />}
        </>
      )}
    </main>
  );
};

export default Home;
