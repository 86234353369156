import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import arrow from "../../assets/img/Icons/learn-more.svg";
import { useTranslation } from "react-i18next";

const ResultSymptom = ({ data }) => {
  // Local State
  const [renderData, setRenderData] = useState([]);

  // Translate
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      let oldData = data.diagnosted_diseases;

      const sortedData = oldData.sort(
        (a, b) =>
          data._diseases_score_json[b.id] - data._diseases_score_json[a.id]
      );
      setRenderData(sortedData);
    }
  }, [data]);
  return (
    <div className="result-symptom">
      <div className="result-container">
        <h6 className="caption">{t("result_symptom.caption")} </h6>
        <div className="level-info">
          <p className="text">{t("result_symptom.high")}</p>
          <p className="text">{t("result_symptom.middle")}</p>
          <p className="text">{t("result_symptom.low")}</p>
        </div>
        {renderData &&
          renderData.map((disease) => (
            <div className="illness" key={disease.id}>
              <div className="degree">
                <div className="status">
                  <span className={disease.importance_level}></span>
                </div>
                <p className="text">{disease.category[0].title}</p>
              </div>
              <h6 className="ilness-name">{disease.title}</h6>
              {disease.advices.length !== 0 && (
                <p className="advise">{disease.advices[0].advice_text}</p>
              )}
              {disease.advices.length !== 0 && (
                <div className="videoDoctorDirect">
                  <Link to={disease.advices[0].advice_link} target="_blank">
                  {t("result_symptom.learn_more")}
                    <span className="icon">
                      <img src={arrow} alt="icon" />
                    </span>
                  </Link>
                </div>
              )}
            </div>
          ))}

        <p className="alert">
        {t("result_symptom.alert")}
        </p>
      </div>
    </div>
  );
};
// hiqh
// middle
// low

export default ResultSymptom;
