/* ---------------------------------- React --------------------------------- */
import React, { useCallback, useContext, useEffect, useState } from "react";

/* ----------------------------- Local Database ----------------------------- */
import questionsDb from "../../db/questionsDb";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const PersonalGenderSelect = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    checkupPage,
    setCheckupPage,
    setGender,
    setPersonal,
    prevButtonRef,
    personal,
    gender,
    token,
    setToken
  } = useContext(MainContext);

  /* ------------------------------- Local State ------------------------------ */
  const [data, setData] = useState([]);

  // Language
  const { i18n } = useTranslation();

  useEffect(() => {
    questionsDb.filter((item) => item.page === checkupPage && setData(item));
  }, [checkupPage]);

  /* ------------------------------ OnClick Logic  ----------------------------- */
  const handleClickFirst = () => {
    if (checkupPage === "gender-select") {
      setCheckupPage("specific-info");
      setGender("female");
    } else {
      // setCheckupPage("gender-select");
      userDataCreate("self",token);
      setPersonal("myself");
    }
  };
  const handleClickSecond = () => {
    if (checkupPage === "gender-select") {
      setCheckupPage("specific-info");
      setGender("male");
    } else {
      // setCheckupPage("gender-select");
      userDataCreate("someone",token);
      setPersonal("someone-else");
    }
  };

  const userDataCreate = useCallback(
    async (userType,handleToken) => {
      await axios
        .post(`https://api.medcheckup.az/api/user-data/create/?lang=en`, {
          token: handleToken,
          checkup_for: userType,
        })
        .then((res) => {
          if (res) {
            setCheckupPage("gender-select");
          }
        })
        .catch((err) => {
          if (
              err.response.data.token.join("") ===
                "User data with this Token already exists."
          ) {


            const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          const tokenLength = 16;
          for (let i = 0; i < tokenLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
          }
          setToken(result);
          userDataCreate(userType,result)
            // setCheckupPage("gender-select");

          }
        });
    },
    [setCheckupPage, setToken]
  );

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const clickPrevBtn = () => {
      if (checkupPage === "personal-select") {
        setCheckupPage("general-info");
      } else if (checkupPage === "gender-select") {
        setCheckupPage("personal-select");
      } else {
        return false;
      }
    };

    if (prevButton) {
      prevButton.addEventListener("click", clickPrevBtn);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", clickPrevBtn);
      };
    }
  }, [prevButtonRef, setCheckupPage, checkupPage]);

  return (
    <div className="personal-gender-select">
      {/* Gender Select */}
      <div
        className={
          checkupPage === "personal-select"
            ? personal === "myself"
              ? "card active"
              : "card"
            : gender === "female"
            ? "card active"
            : "card"
        }
        onClick={() => handleClickFirst()}
      >
        <div className="card-image">
          {checkupPage === "personal-select" ? (
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40 40C49.2048 40 56.6667 32.5381 56.6667 23.3333C56.6667 14.1286 49.2048 6.66667 40 6.66667C30.7953 6.66667 23.3333 14.1286 23.3333 23.3333C23.3333 32.5381 30.7953 40 40 40Z"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M68.6335 73.3333C68.6335 60.4333 55.8001 50 40.0001 50C24.2001 50 11.3668 60.4333 11.3668 73.3333"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.0001 53.3333C52.8867 53.3333 63.3334 42.8866 63.3334 30C63.3334 17.1134 52.8867 6.66667 40.0001 6.66667C27.1134 6.66667 16.6667 17.1134 16.6667 30C16.6667 42.8866 27.1134 53.3333 40.0001 53.3333Z"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M40 53.3333V73.3333"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M50 63.3333H30"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        <h3 className="title">{data[`firstTitle_${i18n.language}`]}</h3>
      </div>

      {/* Personal Select */}

      <div
        className={
          checkupPage === "personal-select"
            ? personal === "someone-else"
              ? "card active"
              : "card"
            : gender === "male"
            ? "card active"
            : "card"
        }
        onClick={() => handleClickSecond()}
      >
        <div className="card-image">
          {checkupPage === "personal-select" ? (
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5335 36.2333C30.2002 36.2 29.8002 36.2 29.4335 36.2333C21.5002 35.9667 15.2002 29.4667 15.2002 21.4667C15.2002 13.3 21.8002 6.66667 30.0002 6.66667C38.1669 6.66667 44.8002 13.3 44.8002 21.4667C44.7669 29.4667 38.4669 35.9667 30.5335 36.2333Z"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M54.7 13.3333C61.1667 13.3333 66.3667 18.5667 66.3667 25C66.3667 31.3 61.3667 36.4333 55.1333 36.6667C54.8667 36.6333 54.5667 36.6333 54.2667 36.6667"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.8667 48.5333C5.80005 53.9333 5.80005 62.7333 13.8667 68.1C23.0334 74.2333 38.0667 74.2333 47.2334 68.1C55.3 62.7 55.3 53.9 47.2334 48.5333C38.1 42.4333 23.0667 42.4333 13.8667 48.5333Z"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M61.1337 66.6667C63.5337 66.1667 65.8004 65.2 67.6671 63.7667C72.8671 59.8667 72.8671 53.4333 67.6671 49.5333C65.8337 48.1333 63.6004 47.2 61.2337 46.6667"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.1668 71.6667C48.4342 71.6667 60.0002 60.1007 60.0002 45.8333C60.0002 31.566 48.4342 20 34.1668 20C19.8995 20 8.3335 31.566 8.3335 45.8333C8.3335 60.1007 19.8995 71.6667 34.1668 71.6667Z"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M71.6668 8.33335L53.3335 26.6667"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M50 8.33335H71.6667V30"
                stroke="#3A7D98"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        <h3 className="title">{data[`secondTitle_${i18n.language}`]}</h3>
      </div>
    </div>
  );
};

export default PersonalGenderSelect;
