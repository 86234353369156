import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Auth = createContext();

export const Validation = ({ children }) => {
  const { pathname } = useLocation();
  const location = useLocation();

  const [authToken, setAuthToken] = useState("");
  const [refleshToken, setRefleshToken] = useState("");
  const [userId, setUserId] = useState("");

  //Login
  const [loginVisible, setLoginVisible] = useState(false);

  // Checkup History
  const [checkupData, setCheckupData] = useState(null);

  const navigate = useNavigate();

  const checkUserIsIn = useCallback(async () => {
    try {
      let user = await JSON.parse(localStorage.getItem("token"));
      let refresh = await JSON.parse(localStorage.getItem("token"));

      // CheckToken
      await axios
        .get(`https://api.medcheckup.az/api/user/id/?token=${user}&lang=en`)
        .then((res) => {
          res && setUserId(res.data.userId);

          if (res.data.code === 103 || res.data.code === 104) {
            // Refresh Token
            axios
              .post(`https://api.medcheckup.az/api/auth/refresh/?lang=en`, {
                refresh_token: refleshToken,
              })
              .then((res) => {
                setAuthToken(res.data.auth);
                setRefleshToken(res.data.refresh);
              })
              .catch((err) => {
                setAuthToken("");
                setRefleshToken("");
              });
          }
        })
        .catch((err) => {
          setUserId("");
          setAuthToken("");
          setRefleshToken("");
        });
      setAuthToken(user);
      setRefleshToken(refresh);
    } catch (error) {
      setUserId("");
      setAuthToken("");
      setRefleshToken("");
    }
  }, [refleshToken]);

  useEffect(() => {
    checkUserIsIn();
  }, [authToken, checkUserIsIn]);

  // Get User Id
  const getUserId = useCallback(async (token) => {
    await axios
      .get(`https://api.medcheckup.az/api/user/id/?token=${token}&lang=en`)
      .then((res) => res && setUserId(res.data.userId))
      .catch((err) => err && setUserId(""));
  }, []);

  // Check Token For Pick User Id
  useEffect(() => {
    if (authToken) {
      getUserId(authToken);
    }
  }, [authToken, getUserId]);

  // useEffect(() => {
  //   const getToken = () => {
  //     const saveData = JSON.parse(localStorage.getItem("token"));
  //     const pages = [
  //       "",
  //       "login",
  //       "sign-up",
  //       "forgot-password",
  //       "profile",
  //     ];
  //     if (saveData !== null) {
  //       // axios
  //       //   .get(
  //       //     `https://api.uimconsulting.com/en/v3/auths/checktoken?token=${saveData}&type=4`,
  //       //     {
  //       //       crossdomain: true,
  //       //     }
  //       //   )
  //       //   .then((res) => {
  //       //     if (
  //       //       res.data.status === false ||
  //       //       res.data.message === "Token expired!"
  //       //     ) {
  //       //       setAuthToken("");
  //       //       // profil seyfeden asilli ele
  //       //       if (!pages.includes(location.pathname.split("/")[1])) {
  //       //         navigate("/");
  //       //       }
  //       //     } else if (res.data.status === "success") {
  //       //       setAuthToken(saveData);
  //       //     }
  //       //   })
  //       //   .catch((err) => console.log(err));
  //     }
  //   };
  //   getToken();
  // }, []);

  const logOut = () => {
    localStorage.setItem("token", JSON.stringify(""));
    setAuthToken("");
    setRefleshToken("");

    navigate("/");
  };
  const globalStates = {
    // State
    authToken,
    setAuthToken,
    refleshToken,
    setRefleshToken,
    userId,
    setUserId,

    // login visible
    loginVisible,
    setLoginVisible,

    // Checkup Data
    checkupData,
    setCheckupData,

    // Function
    logOut,
  };

  return <Auth.Provider value={globalStates}>{children}</Auth.Provider>;
};
