// React
import { useContext, useEffect, useState } from "react";

// Component
import SearchSymptom from "./SearchSymptom";
import SearchKeywordsArea from "./SearchKeywordsArea";
import BodyDesktop from "./BodyDesktop";
import BodyMobile from "./BodyMobile";
import SideBar from "./SideBar";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SelectSymptom = () => {
  // Global State
  const {
    symptomBar,
    setSymptomBar,
    prevButtonRef,
    nextButtonRef,
    setCheckupPage,
    setBodyParts,
    symptomList,

    // Function
    nextPageDirectroy,
  } = useContext(MainContext);

  // Local State
  // Detect device width for render box component
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectSymptomPageError, setSelectSymptomPageError] = useState(false);

  // Translate
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // All Body Part
  useEffect(() => {
    axios
      .get("https://api.medcheckup.az/api/bodyparts/?lang=en")
      .then((res) => res && setBodyParts(res.data.results))
      .catch((err) => console.log(err));
  }, [setBodyParts]);

  useEffect(() => {
    if (symptomList.list.length !== 0 && selectSymptomPageError) {
      setSelectSymptomPageError(false);
    }
  }, [
    selectSymptomPageError,
    setSelectSymptomPageError,
    symptomList.list.length,
  ]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("general-preliminary-inspection");
      if (selectSymptomPageError) {
        setSelectSymptomPageError(false);
      }
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, selectSymptomPageError, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      if (symptomList.list.length !== 0) {
        nextPageDirectroy(0);
      } else {
        setSelectSymptomPageError(true);
      }
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    nextButtonRef,
    nextPageDirectroy,
    setSelectSymptomPageError,
    symptomList.list.length,
  ]);

  return (
    <div className="select-symptom">
      <div className="left-side">
        <SearchSymptom />
        <SearchKeywordsArea />
        {selectSymptomPageError && (
          <p className="error">{t("select_symptom.error")}</p>
        )}
      </div>
      <div className="right-side">
        {windowWidth > 1000 ? <BodyDesktop /> : <BodyMobile />}
      </div>
      <SideBar />
      <div
        className={symptomBar ? "overlay active" : "overlay"}
        onClick={() => setSymptomBar(false)}
      ></div>
    </div>
  );
};

export default SelectSymptom;
