import React from 'react'

const HomeCard = ({data}) => {
  return (
    <div className="home-card" key={data.id}>
    <div className="top">
      <img src={data.icon} alt="icon" />
    </div>
    <div className="bottom">
      <h6 className="title">{data.title}</h6>
      <span className="line"></span>
      <p className="text">
      {data.description}
      </p>
    </div>
  </div>
  )
}

export default HomeCard