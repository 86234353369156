import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import CheckupCaption from "./CheckupCaption";
import { useTranslation } from "react-i18next";

const YesNoQuestion = () => {
  // Global State
  const {
    prevButtonRef,
    setCheckupPage,
    symptomList,
    setSymptomList,

    // Function
    nextPageDirectroy,
    prevPageDirectroy,
  } = useContext(MainContext);

  // Local State
  const [renderData, setRenderData] = useState(null);

  // Language
  const{t}=useTranslation()

  // CheckData
  useEffect(() => {
    setRenderData(null);
    if (
      symptomList.list[symptomList.currentInnerSymptom].widget_for_question ===
      "yes_no"
    ) {
      setRenderData(symptomList.list[symptomList.currentInnerSymptom]);
    }
  }, [
    symptomList.currentInnerSymptom,
    symptomList.currentPageIndex,
    symptomList.list,
  ]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const prevButtonClickHandler = () => {
      prevPageDirectroy(symptomList.currentInnerSymptom - 1);
    };

    if (prevButton) {
      prevButton.addEventListener("click", prevButtonClickHandler);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", prevButtonClickHandler);
      };
    }
  }, [
    nextPageDirectroy,
    prevButtonRef,
    prevPageDirectroy,
    setCheckupPage,
    symptomList.currentInnerSymptom,
  ]);

  // Selected And Next Page
  const selectOption = (selectType) => {

    // let updateInnerData = resetSelected.map((subsymptom) => {
    //   if (subsymptom.id === Number(data.radio)) {
    //     return { ...subsymptom, select: true };
    //   }
    //   return subsymptom;
    // });

    let generalDatas=symptomList.list
    generalDatas[symptomList.currentInnerSymptom] = {
      ...generalDatas[symptomList.currentInnerSymptom], // Mevcut objeyi koru
      select: selectType , // Sadece 'inner' key'ini değiştir
    };
  

    setRenderData({ ...renderData, select: selectType });
    setSymptomList((prev) => ({
      ...prev,
      list: prev.list.map((item) =>
        item.id === renderData.id ? { ...item, select: selectType } : item
      ),
    }));
    

    // Next Page
    nextPageDirectroy(symptomList.currentInnerSymptom + 1,generalDatas);
  };

  return (
    renderData && (
      <>
        <CheckupCaption title={renderData.question_for_inner_symptoms} />
        <div className="yes-no-question">
          <div className="row">
            {/* Yes */}
            <div
              className={renderData?.select === "yes" ? "card active" : "card "}
              onClick={() => {
                selectOption("yes");
              }}
            >
              <div className="icon">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.585 50.0003L38.1683 73.5837L85.4183 26.417"
                    stroke="#095D7E"
                    strokeWidth="12.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4 className="caption">{t("general.yes")}</h4>
            </div>
            {/* No */}
            <div
              className={renderData?.select === "no" ? "card active" : "card "}
              onClick={() => {
                selectOption("no");
              }}
            >
              <div className="icon">
                <svg
                  width="101"
                  height="100"
                  viewBox="0 0 101 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.748 73.5837L73.9147 26.417"
                    stroke="#095D7E"
                    strokeWidth="12.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M73.9147 73.5837L26.748 26.417"
                    stroke="#095D7E"
                    strokeWidth="12.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4 className="caption">{t("general.no")}</h4>
            </div>
            {/* I Dont Know */}
            <div
              className={
                renderData?.select === "don’t know" ? "card active" : "card "
              }
              onClick={() => {
                selectOption("don’t know");
              }}
            >
              <div className="icon">
                <svg
                  width="101"
                  height="100"
                  viewBox="0 0 101 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M58.998 20.8343L88.1641 50.0004L58.998 79.1665"
                    stroke="#095D7E"
                    strokeWidth="12.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.332 49.9999L83.9987 49.9999"
                    stroke="#095D7E"
                    strokeWidth="12.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4 className="caption">{t("general.i_dont_know")}</h4>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default YesNoQuestion;
