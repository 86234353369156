import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/huge-logo.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";
import { useContext, useEffect, useState } from "react";
import { Auth } from "../utils/Auth";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import errorIcon from "../assets/img/Icons/error.svg";
import { useTranslation } from "react-i18next";
import loadinggif from "../assets/img/loading-gif.gif";
const ForgetPassword = () => {
  // Global State
  const { setLoginVisible } = useContext(Auth);

  // Local State
  const [step, setStep] = useState(1);
  const [errorText, setErrorText] = useState("");
  const [operationID, setOperationId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userVerifyContent, setUserVerifyContent] = useState("");

  // Translate
  const { i18n } = useTranslation();

  // Navigate
  const navigate = useNavigate();

  const userVerify = object({
    user: string().required().trim(),
  });
  const otpVerify = object({
    otp: string().required().trim(),
  });
  const passVerify = object({
    password: string().required().trim(),
    repassword: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(
      step === 1 ? userVerify : step === 2 ? otpVerify : passVerify
    ),
  });

  // Reset step
  useEffect(() => {
    setStep(1);
    setErrorText("");
    setOperationId("");
    setIsSubmitting(false);
    setUserVerifyContent("");
  }, []);

  function formatPhoneNumber(input) {
    // Telefon numarasını temizlemek için regex
    const phoneRegex =
      /(?:\+994|994|0)(\d{2})[\s\-]?(\d{3})[\s\-]?(\d{2})[\s\-]?(\d{2})/;

    // Eşleşme varsa
    const match = input.trim().match(phoneRegex);

    if (match) {
      // +994 ile başlayacak şekilde formatla
      const formattedPhone = `+994${match[1]}${match[2]}${match[3]}${match[4]}`;
      return formattedPhone;
    } else {
      // Eğer telefon numarası değilse, sadece +994 ile dön
      return input.trim();
    }
  }

  const submitUser = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await axios
      .post(
        `https://api.medcheckup.az/api/auth/reset-password-request/?lang=${i18n.language}`,
        {
          email: formatPhoneNumber(data.user),
        }
      )
      .then((res) => {
        setUserVerifyContent(data.user);
        setOperationId(res.data.operation);
        setStep(2);
        reset();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSubmitting(false));
  };
  const submitOtp = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!operationID) {
      setStep(1);
      reset();
      setIsSubmitting(false);
    }
    await axios
      .post(
        `https://api.medcheckup.az/api/auth/verify-reset-code/?lang=${i18n.language}`,
        {
          operation_id: operationID,
          code: data.otp,
        }
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status === 1 && res.data.subject === 0) {
          setErrorText("Code expired");
        } else if (res.data.status === 0 && res.data.subject === 3) {
          console.log(res.data.status);
          setStep(3);
          reset();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSubmitting(false));
  };

  const submitLogin = async (data) => {
    if (data.password === data.repassword) {
      let body = {
        operation_id: operationID,
        email: userVerifyContent,
        new_password: data.password,
      };

      if (isSubmitting) return;
      setIsSubmitting(true);

      await axios
        .post(
          `https://api.medcheckup.az/api/auth/restore-password/?lang=${i18n.language}`,
          body
        )
        .then((res) => {
          console.log(res);

          setStep(1);
          setErrorText("");
          setOperationId("");
          setIsSubmitting(false);
          setUserVerifyContent("");
          reset();
          navigate("/");
          setLoginVisible(true);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsSubmitting(false));
    } else {
      setErrorText(" Password təkrarı düzgün deyil");
      setIsSubmitting(false);
    }
  };
  return (
    <main>
      <section className="form-page">
        <div className="container">
          <div className="row">
            <div className="left-side">
              <div className="logo-box">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            {step === 1 ? (
              // User verify
              <form className="right-side" onSubmit={handleSubmit(submitUser)}>
                <div className="info-group">
                  <h1 className="caption">Fogot password</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>
                <div className="form-group">
                  <label
                    className={errors.user ? "label error" : "label"}
                    htmlFor="user"
                  >
                    Login or Email or Phone
                  </label>
                  <input
                    className={errors.user ? "inp error" : "inp"}
                    type="text"
                    id="user"
                    name="user"
                    {...register("user", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                <button
                  className={isSubmitting ? "submit specific" : "submit  "}
                >
                  <img src={loadinggif} alt="gif" />
                  Confirm
                </button>
                <p className="login-adviser">
                  Don’t have an account?{" "}
                  <Link to="/" onClick={() => setLoginVisible(true)}>
                    Login
                  </Link>
                </p>
              </form>
            ) : step === 2 ? (
              // Otp verify
              <form className="right-side" onSubmit={handleSubmit(submitOtp)}>
                <div className="info-group">
                  <h1 className="caption">Otp</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>

                <div className="form-group">
                  <label
                    className={errors.otp ? "label error" : "label"}
                    htmlFor="otp"
                  >
                    Otp Code{" "}
                  </label>
                  <input
                    className={errors.otp ? "inp error" : "inp"}
                    type="text"
                    id="otp"
                    name="otp"
                    {...register("otp", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                  {errorText && (
                    <div className="error-component">
                      <div className="info">
                        <div className="icon">
                          <img src={errorIcon} alt="error" />
                        </div>
                        <span className="text">{errorText}</span>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className={isSubmitting ? "submit specific" : "submit  "}
                >
                  <img src={loadinggif} alt="gif" />
                  Confirm Code
                </button>
              </form>
            ) : (
              // Change Password
              <form className="right-side" onSubmit={handleSubmit(submitLogin)}>
                <div className="info-group">
                  <h1 className="caption">Change password</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>

                <div className="form-group">
                  <div className="top">
                    <label
                      className={errors.password ? "label error" : "label"}
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <button className="icon">
                      <img src={hide} alt="unhide" />
                    </button>
                  </div>
                  <input
                    className={errors.password ? "inp error" : "inp"}
                    type="password"
                    id="password"
                    name="password"
                    {...register("password", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                <div className="alert-area">
                  <div className="alert-group">
                    <p className="detail">Use 8 or more characters</p>
                    <p className="detail">
                      Use upper and lower case letters (e.g. Aa)
                    </p>
                  </div>
                  <div className="alert-group">
                    <p className="detail">Use a number (e.g. 1234)</p>
                    <p className="detail">Use a symbol (e.g. !@#$)</p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="top">
                    <label
                      className={errors.repassword ? "label error" : "label"}
                      htmlFor="repassword"
                    >
                      Repassword
                    </label>
                    <button className="icon">
                      <img src={unHide} alt="unhide" />
                    </button>
                  </div>
                  <input
                    className={errors.repassword ? "inp error" : "inp"}
                    type="password"
                    id="repassword"
                    name="repassword"
                    {...register("repassword", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                  {errorText && (
                    <div className="error-component">
                      <div className="info">
                        <div className="icon">
                          <img src={errorIcon} alt="error" />
                        </div>
                        <span className="text">{errorText}</span>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  className={isSubmitting ? "submit specific" : "submit  "}
                >
                  <img src={loadinggif} alt="gif" />
                  Change Password
                </button>
              </form>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default ForgetPassword;
