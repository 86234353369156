import React from "react";
import HomeCaption from "../home-section/HomeCaption";
import HomeCard from "../../component/HomeCard";

const Reference = ({ referanceData = {},referanceCards=[] }) => {
  const { title, description } = referanceData;
  return (
    <section className="reference">
      <div className="container">
        <div className="head">
          <HomeCaption title={title} detail={description} />
        </div>
        <div className="body">
          <div className="row">
            {referanceCards.map((item) => (
              <HomeCard key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reference;
