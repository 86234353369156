import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  // Language
  const { i18n } = useTranslation();
  const [prevLang, setPrevLang] = useState("");

  // Sesion Token
  const [token, setToken] = useState("");

  /* ---------------------------- Complete Checkup ---------------------------- */
  const [complete, setComplete] = useState(false);

  /* ---------------------------- Component Render ---------------------------- */
  const [checkupPage, setCheckupPage] = useState("general-info");

  /* ------------------------------ Specific Info ----------------------------- */
  const [bodyInfo, setBodyInfo] = useState({
    age: "18",
    weight: "50",
    height: "150",
  });
  const [gender, setGender] = useState("");
  const [personal, setPersonal] = useState("");

  /* -------------------------- SideBar Open && close ------------------------- */
  const [symptomBar, setSymptomBar] = useState();
  const [sideBarCaption, setSideBarCaption] = useState({
    caption_az: "",
    caption_en: "",
    type: "",
  });

  /* ------------------------ General Preliminary Inspection Data ------------------------ */

  const [primarySymtiomData, setPrimarySymtiomData] = useState([]);

  /* ---------------------------- Select Symptom List ---------------------------- */
  const [symptomList, setSymptomList] = useState({
    currentInnerSymptom: null,
    list: [],
  });
  /* ---------------------------- Question List ---------------------------- */
  const [questionList, setQuestionList] = useState([]);
  const [questionStep, setQuestionStep] = useState(0);

  /* --------------------------------- Region --------------------------------- */
  const [continent, setContinent] = useState([]);
  const [selectRegionObj, setSelectRegionObj] = useState([]);

  /* -------------------------------- BodyParts ------------------------------- */
  const [bodyParts, setBodyParts] = useState([]);

  /* -------------------------------- PainLevel ------------------------------- */
  const [painLevel, setPainlevel] = useState(1);

  /* ------------------------------ Video Visible ----------------------------- */
  const [videoVisible, setVideoVisible] = useState(false);

  /* -------------------------- Next Prev Button Ref -------------------------- */
  const [nextButtonRef, setNextButtonRef] = useState([]);
  const [prevButtonRef, setPrevButtonRef] = useState([]);

  /* ---------------------------------- Step ---------------------------------- */
  const [step, setStep] = useState(1);

  // Profile
  const [profileActivePage, setProfileActivePage] = useState("Account");

  /* -------------------------------- Function -------------------------------- */

  const resetStates = () => {
    setPrevLang("");
    setToken("");
    setComplete(false);
    setCheckupPage("general-info");
    setBodyInfo({
      age: "18",
      weight: "50",
      height: "150",
    });
    setGender("");
    setPersonal("");
    setSymptomBar();
    setSideBarCaption({
      caption_az: "",
      caption_en: "",
      type: "",
    });
    setPrimarySymtiomData([]);
    setSymptomList({
      currentInnerSymptom: null,
      list: [],
    });
    setQuestionList([]);
    setQuestionStep(0);
    setContinent([]);
    setSelectRegionObj([]);
    setBodyParts([]);
    setPainlevel(1);
    setVideoVisible(false);
    setStep(1);
    setProfileActivePage("Account");
  };

  // Remove Data in StmptomList
  const removeSymptomInList = (symptomId) => {
    const updatedSymptomList = symptomList.list.filter(
      (item) => item.id !== symptomId
    );
    setSymptomList({ ...symptomList, list: updatedSymptomList });
  };

  const questionTranslate = useCallback(
    async (questions, step) => {
      let currentQuestionList = questions[step];
      let ids = currentQuestionList?.list
        .map((question) => question.id)
        .join("%2C");
      await axios
        .get(
          ` https://api.medcheckup.az/api/questions/all/ids/?ids=${ids}&lang=${i18n.language}`
        )
        .then((res) => {
          let results = res.data.results;

          const updatedList = results.map((resultItem) => {
            // currentQuestionList?.list içinde resultItem.id ile eşleşen objeyi bul
            const matchedItem = currentQuestionList?.list.find(
              (item) => item.id === resultItem.id
            );

            // Eğer matchedItem varsa ve selectedId tanımlıysa, ekle; yoksa ekleme
            return matchedItem && matchedItem.selectedId !== undefined
              ? { ...resultItem, selectedId: matchedItem.selectedId }
              : resultItem;
          });
          setQuestionList((prevState) =>
            prevState.map((item, i) =>
              i === step ? { ...item, list: updatedList } : item
            )
          );

          if (step !== 0) {
            questionTranslate(questions, step - 1);
          }
        })
        .catch((err) => console.log(err));
    },
    [i18n.language]
  );

  const symptomTranslate = useCallback(
    async (symptomListData) => {
      let ids = symptomListData?.list.map((symptom) => symptom.id).join("%2C");

      await axios
        .get(
          `https://api.medcheckup.az/api/symptoms/all/?ids=${ids}&lang=${i18n.language}`
        )
        .then((res) => {
          // New Data
          let newData = res.data.results;

          /* ---------------------------- Pick Sytpom Types --------------------------- */
          let painLevelTemperatureSymptoms = symptomListData.list.filter(
            (symptom) =>
              (symptom.widget_for_question === "pain_level" ||
                symptom.widget_for_question === "temprature") &&
              symptom.select !== 0 &&
              symptom
          );
          let yesNOSymptoms = symptomListData.list.filter(
            (symptom) => symptom.widget_for_question === "yes_no" && symptom
          );

          let radioButtonSymptoms = symptomListData.list.filter(
            (symptom) =>
              (symptom.widget_for_question ===
                "('radio_buttons', 'radio_buttons')" ||
                symptom.widget_for_question === "radio_buttons") &&
              symptom
          );

          let checkboxSymptoms = symptomListData.list.filter(
            (symptom) => symptom.widget_for_question === "checkbox" && symptom
          );

          /* ----------------- Update Pain Level/ Yes NO /Temperature ----------------- */
          const updatedData = newData.map((newda) => {
            const matchingOld = painLevelTemperatureSymptoms.find(
              (old) => old.id === newda.id && old.select
            );
            const matchingYesNo = yesNOSymptoms.find(
              (old) => old.id === newda.id && old.select
            );

            if (matchingOld) {
              return {
                ...newda,
                select: matchingOld.select,
                userSelect: matchingOld.userSelect,
              };
            }
            if (matchingYesNo) {
              return {
                ...newda,
                select: matchingYesNo.select,
              };
            }

            return newda;
          });

          /* ------------------------ Update Radio Button Type ------------------------ */
          let updatedDataWithRadioButtonSymptoms = updatedData.map((newds) => {
            // Her bir newds objesi üzerinde işlem yapıyoruz
            radioButtonSymptoms.forEach((old) => {
              if (newds.id === old.id) {
                // inner_symptoms array'lerini karşılaştır
                old.inner_symptoms.forEach((oldSymptom) => {
                  if (oldSymptom.select) {
                    // Eğer select özelliği varsa
                    // select değerini ilgili newds içindeki inner_symptoms objesine ekleyelim
                    newds.inner_symptoms.forEach((newSymptom) => {
                      // Eğer aynı id'ye sahip bir inner_symptom varsa
                      if (newSymptom.id === oldSymptom.id) {
                        // select değerini inner_symptom'a ekle
                        newSymptom.select = oldSymptom.select;
                      }
                    });
                  }
                });
              }
            });

            return newds; // Güncellenmiş newds objesini geri döndür
          });

          /* ----------------------------- Update Checkbox ---------------------------- */
          let updatedDataWithCheckboxSymptoms =
            updatedDataWithRadioButtonSymptoms.map((newds) => {
              // Her bir newds objesi üzerinde işlem yapıyoruz
              checkboxSymptoms.forEach((old) => {
                if (newds.id === old.id) {
                  // inner_symptoms array'lerini karşılaştır
                  old.inner_symptoms.forEach((oldSymptom) => {
                    if (oldSymptom.select) {
                      // Eğer select özelliği varsa
                      // select değerini ilgili newds içindeki inner_symptoms objesine ekleyelim
                      newds.inner_symptoms.forEach((newSymptom) => {
                        // Eğer aynı id'ye sahip bir inner_symptom varsa
                        if (newSymptom.id === oldSymptom.id) {
                          // select değerini inner_symptom'a ekle
                          newSymptom.select = oldSymptom.select;
                        }
                      });
                    }
                  });
                }
              });

              return newds; // Güncellenmiş newds objesini geri döndür
            });

          /* -------------------------- Sort OLd Array Style -------------------------- */
          let updatedDataSorted = updatedDataWithCheckboxSymptoms.sort(
            (a, b) => {
              let indexA = symptomListData?.list.findIndex(
                (item) => item.id === a.id
              );
              let indexB = symptomListData?.list.findIndex(
                (item) => item.id === b.id
              );

              return indexA - indexB;
            }
          );

          setSymptomList((prevState) => ({
            ...prevState,
            list: updatedDataSorted,
          }));
        })
        .catch((err) => console.log(err));
    },
    [i18n.language]
  );

  // Question Change Lang
  useEffect(() => {
    if (prevLang === "") {
      setPrevLang(i18n.language);
    } else if (
      checkupPage === "question" &&
      questionList &&
      prevLang !== i18n.language
    ) {
      questionTranslate(questionList, questionStep);
      setPrevLang(i18n.language);
    }
  }, [
    checkupPage,
    i18n.language,
    prevLang,
    questionList,
    questionStep,
    questionTranslate,
  ]);

  // Symptom Change Lang
  useEffect(() => {
    if (prevLang === "") {
      setPrevLang(i18n.language);
    } else if (symptomList.list.length !== 0 && prevLang !== i18n.language) {
      symptomTranslate(symptomList);
      setPrevLang(i18n.language);
    }
  }, [
    checkupPage,
    i18n.language,
    prevLang,
    symptomList,
    symptomList.list,
    symptomTranslate,
  ]);

  const questionGetFirstRaundData = useCallback(async () => {
    await axios
      .get(
        `https://api.medcheckup.az/api/questions/${token}/1/?lang=${i18n.language}`
      )
      .then((res) => {
        // if (res.data.results.length !== 0){

        setQuestionList([{ step: 1, list: res.data.results }]);

        setQuestionStep(0);
        setCheckupPage("question");
        // }else {
        //   setCheckupPage("result");
        //   setComplete(true);
        // }
      })
      .catch((err) => console.log(err));
  }, [i18n.language, token]);

  const userDataAddSymptom = async (allSymtom) => {
    /* --------------------------- Pick Radio Type ids -------------------------- */
    let radioList = allSymtom.filter(
      (symptom) =>
        (symptom.widget_for_question === "('radio_buttons', 'radio_buttons')" ||
          symptom.widget_for_question === "radio_buttons") &&
        symptom
    );
    if (radioList) {
      var radioSubSymptomIds = radioList
        ?.map(
          (symptom) =>
            symptom.inner_symptoms
              .filter((subsymptom) => subsymptom.select) // select olanları filtrele
              .map((subsymptom) => subsymptom.id) // sadece id değerlerini al
        )
        .flat();
    }

    /* ------------------------- Pick Checkbox Type ids ------------------------- */
    let checkboxList = allSymtom.filter(
      (symptom) => symptom.widget_for_question === "checkbox" && symptom
    );
    if (checkboxList) {
      var checkboxSubSymptomIds = checkboxList
        ?.map(
          (symptom) =>
            symptom.inner_symptoms
              .filter((subsymptom) => subsymptom.select) // select olanları filtrele
              .map((subsymptom) => subsymptom.id) // sadece id değerlerini al
        )
        .flat();
    }

    /* ------------------------------- Pick Temperature Ids ------------------------------ */
    let temprature = allSymtom
      .filter(
        (symptom) =>
          symptom.widget_for_question === "temprature" &&
          symptom.select !== 0 &&
          symptom
      )
      .map((item) => item.id);

    /* ------------------------------- Pick Pain Level  Ids ------------------------------ */
    let painLevel = allSymtom
      .filter(
        (symptom) =>
          symptom.widget_for_question === "pain_level" &&
          symptom.select !== 0 &&
          symptom
      )
      .map((item) => item.id);

    /* ----------------------------- Pick Yes No Ids ---------------------------- */
    let yesNo = allSymtom
      .filter(
        (symptom) =>
          symptom.widget_for_question === "yes_no" &&
          symptom.select === "yes" &&
          symptom
      )
      .map((item) => item.id);

    /* ---------------------------- Result Ids Array ---------------------------- */
    const generalArr = temprature.concat(
      radioSubSymptomIds,
      radioList.map((item) => item.id),
      checkboxSubSymptomIds,
      checkboxList.map((item) => item.id),
      painLevel,
      yesNo
    );

    await axios
      .put(
        `https://api.medcheckup.az/api/user-data/add/${token}/?lang=${i18n.language}`,
        {
          symptoms: generalArr,
        }
      )
      .then((res) => res && questionGetFirstRaundData())
      .catch((err) => console.log(err));
  };

  // Chec Data For Prev Page
  const prevPageDirectroy = (currentPageIndex) => {
    if (currentPageIndex !== -1 && symptomList.list.length !== 0) {
      prevPageDirectroy(currentPageIndex - 1);
      const currentSymptom = symptomList.list[currentPageIndex];
      if (
        currentSymptom &&
        currentSymptom.inner_symptoms &&
        currentSymptom.inner_symptoms.length === 0
      ) {
        // if (
        //   symptomList.list[currentPageIndex].inner_symptoms.length === 0 ||
        //   symptomList.list[currentPageIndex].inner_symptoms === null
        // ) {
        prevPageDirectroy(currentPageIndex - 1);
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "checkbox"
      ) {
        setCheckupPage("dynamic-symptom");
        // complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "temprature"
      ) {
        setCheckupPage("temprature");
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "pain_level"
      ) {
        setCheckupPage("pain-level");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "yes_no"
      ) {
        setCheckupPage("yes-no-question");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question ===
          "radio_buttons" ||
        symptomList.list[currentPageIndex].widget_for_question ===
          "('radio_buttons', 'radio_buttons')"
      ) {
        setCheckupPage("dynamic-symptom");
      } else {
        // reset all compnent and direct error page
      }
      setSymptomList((prevState) => ({
        ...prevState,
        currentInnerSymptom: currentPageIndex,
      }));

      // widget_for_question
    } else {
      setCheckupPage("select-symptom");
    }
  };

  // Chec Data For Next Page
  const nextPageDirectroy = (currentPageIndex, symptoms = []) => {
    if (currentPageIndex !== symptomList.list.length) {
      if (
        symptomList.list[currentPageIndex].inner_symptoms.length === 0 ||
        symptomList.list[currentPageIndex].inner_symptoms === null
      ) {
        nextPageDirectroy(currentPageIndex + 1, symptoms);
        return;
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "checkbox"
      ) {
        setCheckupPage("dynamic-symptom");
        // complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "temprature"
      ) {
        setCheckupPage("temprature");
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "pain_level"
      ) {
        setCheckupPage("pain-level");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question === "yes_no"
      ) {
        setCheckupPage("yes-no-question");
        // Complete
      } else if (
        symptomList.list[currentPageIndex].widget_for_question ===
          "radio_buttons" ||
        symptomList.list[currentPageIndex].widget_for_question ===
          "('radio_buttons', 'radio_buttons')"
      ) {
        setCheckupPage("dynamic-symptom");
      } else {
        // reset all compnent and direct error page
      }

      setSymptomList((prevState) => ({
        ...prevState,
        currentInnerSymptom: currentPageIndex,
        // nextInnerSymptom: nextIndex,
      }));

      // widget_for_question
    } else {
      // userData icerisine props kimi id array gondermelisen
      userDataAddSymptom(symptoms);
    }
  };

  const globalStates = {
    // Sesion Token
    token,
    setToken,

    // Export State
    complete,
    setComplete,
    checkupPage,
    setCheckupPage,
    bodyInfo,
    setBodyInfo,
    gender,
    setGender,
    personal,
    setPersonal,

    // GeneralPreliminaryInspection
    primarySymtiomData,
    setPrimarySymtiomData,

    // Question List
    questionList,
    setQuestionList,
    questionStep,
    setQuestionStep,

    symptomBar,
    setSymptomBar,
    sideBarCaption,
    setSideBarCaption,
    symptomList,
    setSymptomList,

    // Region
    continent,
    setContinent,
    selectRegionObj,
    setSelectRegionObj,

    // BodyParts
    bodyParts,
    setBodyParts,

    painLevel,
    setPainlevel,
    nextButtonRef,
    prevButtonRef,
    setNextButtonRef,
    setPrevButtonRef,
    step,
    setStep,
    videoVisible,
    setVideoVisible,

    // Profile
    profileActivePage,
    setProfileActivePage,

    // function
    resetStates,
    removeSymptomInList,
    nextPageDirectroy,
    prevPageDirectroy,
    // evaluationInitialize,
  };
  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
