import ResultSymptom from "../component/result/ResultSymptom";
import ResultGeneralInfo from "../component/result/ResultGeneralInfo";
import AnswerInfo from "../component/result/AnswerInfo";
import { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import Loader from "../component/Loader";
import { useTranslation } from "react-i18next";
import axios from "axios";
const HistoryDetail = () => {
  // Global State
  const { checkupData } = useContext(Auth);

  //   Local State
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const { i18n } = useTranslation();

  //   Navigate
  const navigate = useNavigate();

  const checkupDataRequest = useCallback(async () => {
    setLoading(true);

    await axios
      .get(
        `https://api.medcheckup.az/api/user-data/all-data/${checkupData}/?lang=${i18n.language}`
      )
      .then((res) => setData(res.data))
      .catch((err) => navigate("/error"))
      .finally(() => setLoading(false));
  }, [checkupData, i18n.language, navigate]);

  useEffect(() => {
    setLoading(true);
    if (checkupData && i18n.language) {
      checkupDataRequest();
    } else {
      setData(null);
      setLoading(false);
      navigate("/error");
    }
  }, [checkupData, checkupDataRequest, i18n.language, navigate]);

  return loading ? (
    <Loader />
  ) : (
    <div className="container">
      <ResultGeneralInfo />
      <ResultSymptom data={data} />
      <AnswerInfo data={data} />
    </div>
  );
};

export default HistoryDetail;
