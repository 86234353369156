import { Link } from "react-router-dom";
import logo from "../assets/img/huge-logo.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";
import { useContext, useEffect, useState } from "react";
import { Auth } from "../utils/Auth";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
const SignUp = () => {
  // Global State
  const { setLoginVisible } = useContext(Auth);

  // Local State
  const [step, setStep] = useState(1);
  const [errorText, setErrorText] = useState("");
  const [passVisible, setPassVisible] = useState(false);

  // Reset step
  useEffect(() => {
    setStep(1);
    setErrorText("");
  }, []);

  const schema = object({
    fullname: string().required().trim(),
    email: string().required().trim().email(),
    tel: string().required().trim(),
    password: string().required().trim(),
    repassword: string().required().trim(),
  });

  const confirmOtp = object({
    otp: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(step===1 ? schema : confirmOtp),
  });

  const submitSignUp = async (data) => {
    console.log(data);
  };
  const confirmReg = async (data) => {
    console.log(data);
  };
  return (
    <main>
      <section className="form-page">
        <div className="container">
          <div className="row">
            <div className="left-side">
              <div className="logo-box">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            {step === 1 ? (
              <form
                className="right-side"
                onSubmit={handleSubmit(submitSignUp)}
              >
                <div className="info-group">
                  <h1 className="caption">Create an account</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>
                {/* Fullname */}
                <div className="form-group">
                  <label
                    className={errors.fullname ? "label error" : "label"}
                    htmlFor="fullname"
                  >
                    Full Name
                  </label>
                  <input
                    className={errors.fullname ? "inp error" : "inp"}
                    type="text"
                    id="fullname"
                    name="fullname"
                    {...register("fullname", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                {/* Email */}
                <div className="form-group">
                  <label
                    className={errors.email ? "label error" : "label"}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className={errors.email ? "inp error" : "inp"}
                    type="email"
                    id="email"
                    name="email"
                    {...register("email", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                {/* phone */}
                <div className="form-group">
                  <label
                    className={errors.tel ? "label error" : "label"}
                    htmlFor="tel"
                  >
                    Phone
                  </label>
                  <input
                    className={errors.tel ? "inp error" : "inp"}
                    type="tel"
                    id="tel"
                    name="tel"
                    placeholder="+994xxxxxxxx"
                    {...register("tel", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                {/* Password */}
                <div className="form-group">
                  <div className="top">
                    <label
                      className={errors.password ? "label error" : "label"}
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div
                      className="icon"
                      onClick={() => setPassVisible(!passVisible)}
                    >
                      <img src={passVisible ? hide : unHide} alt="unhide" />
                    </div>
                  </div>
                  <input
                    className={errors.password ? "inp error" : "inp"}
                    type={passVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    {...register("password", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>
                <div className="alert-area">
                  <div className="alert-group">
                    <p className="detail">Use 8 or more characters</p>
                    <p className="detail">
                      Use upper and lower case letters (e.g. Aa)
                    </p>
                  </div>
                  <div className="alert-group">
                    <p className="detail">Use a number (e.g. 1234)</p>
                    <p className="detail">Use a symbol (e.g. !@#$)</p>
                  </div>
                </div>
                {/* Repassword */}
                <div className="form-group">
                  <div className="top">
                    <label
                      className={errors.repassword ? "label error" : "label"}
                      htmlFor="repassword"
                    >
                      Repassword
                    </label>
                    <div
                      className="icon"
                      onClick={() => setPassVisible(!passVisible)}
                    >
                      <img src={passVisible ? hide : unHide} alt="unhide" />
                    </div>
                  </div>
                  <input
                    className={errors.repassword ? "inp error" : "inp"}
                    type={passVisible ? "text" : "password"}
                    id="repassword"
                    name="repassword"
                    {...register("repassword", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>

                <button className="submit">Sing up</button>
                <p className="login-adviser">
                  Do you have an account?{" "}
                  <Link to="/" onClick={() => setLoginVisible(true)}>
                    Log in
                  </Link>
                </p>
              </form>
            ) : (
              <form
                className="right-side"
                onSubmit={handleSubmit(confirmReg)}
              >
                <div className="info-group">
                  <h1 className="caption">Create an account</h1>
                  <p className="sub-title">
                    The customer is very happy, it will be followed customer
                    service. The most important disease
                  </p>
                </div>
                {/* Fullname */}
                <div className="form-group">
                  <label
                    className={errors.fullname ? "label error" : "label"}
                    htmlFor="fullname"
                  >
                    Full Name
                  </label>
                  <input
                    className={errors.fullname ? "inp error" : "inp"}
                    type="text"
                    id="fullname"
                    name="fullname"
                    {...register("fullname", {
                      onChange: (e) => {
                        errorText !== "" && setErrorText("");
                      },
                    })}
                  />
                </div>


                <button className="submit">Confirm</button>
       
              </form>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
