import logo from "../assets/img/Icons/loader-logo.svg";

const Loader = () => {
  return (
    <section className="loader">
      <div className="container">
        <div className="row">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="loader-dots-box" id="cartLoadingDots">
            <span className="loader-dots">
              <b className="rounded-circle"></b>
              <b className="rounded-circle"></b>
              <b className="rounded-circle"></b>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Loader;
