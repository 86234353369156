/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "../../utils/Auth";

const ChangePassword = () => {
  // Global State
  const { authToken, logOut, setAuthToken, setRefleshToken, refleshToken } =
    useContext(Auth);

  // Local State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [succesMsj, setSuccesMsj] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Translate
  const { i18n } = useTranslation();

  useEffect(() => {
    setIsSubmitting(false);
    setSuccesMsj(false);
  }, []);

  const schema = object({
    old_pass: string().required().trim(),
    new_pass: string().required().trim(),
    new_repass: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submit = async (data) => {
    // Check Password
    if (data.new_pass === data.new_repass) {
      let body = {
        token: authToken,
        old_password: data.old_pass,
        new_password: data.new_pass,
      };

      if (isSubmitting) return;
      setIsSubmitting(true);

      await axios
        .post(
          `https://api.medcheckup.az/api/auth/change-password/?lang=${i18n.language}`,
          body
        )
        .then((res) => {
          setIsSubmitting(false);
          if (res.data.code === 1010) {
            setErrorText(res.data.reason);
          } else if (res.data.code === 103 || res.data.code === 104) {
            /* ------------------------------ Reflesh Token ----------------------------- */
            axios
              .post(`https://api.medcheckup.az/api/auth/refresh/?lang=en`, {
                refresh_token: refleshToken,
              })
              .then((res) => {
                setAuthToken(res.data.auth);
                setRefleshToken(res.data.refresh);
                setIsSubmitting(false);
                submit(data);
              })
              .catch((err) => {
                setIsSubmitting(false);

                setErrorText(
                  "Uğursuz proses, yenidən daxil olmaqla yenidən cəhd edin."
                );
                const timer = setTimeout(() => {
                  setErrorText(" ");
                  logOut();
                }, 2000);

                return () => clearTimeout(timer);
              });
          } else {
            /* ----------------------------- Change Password ---------------------------- */
            setSuccesMsj(true);
            setErrorText("Uğurla Dəyişildi");
            const timer = setTimeout(() => {
              setSuccesMsj(false);
              setErrorText(" ");
              logOut();
            }, 2000);

            return () => clearTimeout(timer);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
        });
    } else {
      setErrorText(" Password təkrarı düzgün deyil");
    }
  };

  return (
    <section className="account-password">
      <h1 className="user-name">Kamil Kamilov</h1>
      <form className="personality-information" onSubmit={handleSubmit(submit)}>
        <div className="form-group">
          <label
            htmlFor="old_pass"
            className={errors.old_pass ? "label error" : "label"}
          >
            Enter the previous password
          </label>
          <input
            className={errors.old_pass ? "inp error" : "inp"}
            type="password"
            name="old_pass"
            id="old_pass"
            {...register("old_pass", {
              onChange: (e) => {
                errorText !== "" && setErrorText("");
              },
            })}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="new_pass"
            className={errors.new_pass ? "label error" : "label"}
          >
            Create your new password
          </label>
          <input
            className={errors.new_pass ? "inp error" : "inp"}
            type="password"
            name="new_pass"
            id="new_pass"
            {...register("new_pass", {
              onChange: (e) => {
                errorText !== "" && setErrorText("");
              },
            })}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="new_repass"
            className={errors.new_repass ? "label error" : "label"}
          >
            Write again your new password
          </label>
          <input
            className={errors.new_repass ? "inp error" : "inp"}
            type="password"
            name="new_repass"
            id="new_repass"
            {...register("new_repass", {
              onChange: (e) => {
                errorText !== "" && setErrorText("");
              },
            })}
          />
        </div>
        <div className="button-area">
          {(errorText || succesMsj) && (
            <p className={succesMsj ? "error-alert succes" : "error-alert"}>
              {errorText}
            </p>
          )}

          <button>Change</button>
        </div>
      </form>
    </section>
  );
};

export default ChangePassword;
