import { Link } from "react-router-dom";
import vaccine from "../assets/img/vacine-logo.svg";
import videDoktor from "../assets/img/videoDoktor.svg";
import unHide from "../assets/img/Icons/unhide.svg";
import hide from "../assets/img/Icons/hide.svg";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "../utils/Auth";
import closeIcon from "../assets/img/Icons/close-circle-blue.svg";

import errorIcon from "../assets/img/Icons/error.svg";

const Login = () => {
  // Global State
  const { setAuthToken, setRefleshToken, setLoginVisible, loginVisible } =
    useContext(Auth);

  // Local State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [passVisible, setPassVisible] = useState(false);

  // Translate
  const { i18n } = useTranslation();

  const schema = object({
    email: string().required().trim(),
    password: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (loginVisible) {
      setIsSubmitting(false);
      setPassVisible(false);
      reset();
    }
  }, [loginVisible, reset]);

  function formatPhoneNumber(input) {
    // Telefon numarasını temizlemek için regex
    const phoneRegex =
      /(?:\+994|994|0)(\d{2})[\s\-]?(\d{3})[\s\-]?(\d{2})[\s\-]?(\d{2})/;

    // Eşleşme varsa
    const match = input.trim().match(phoneRegex);

    if (match) {
      // +994 ile başlayacak şekilde formatla
      const formattedPhone = `+994${match[1]}${match[2]}${match[3]}${match[4]}`;
      return formattedPhone;
    } else {
      // Eğer telefon numarası değilse, sadece +994 ile dön
      return `+994${input.trim()}`;
    }
  }

  const submitLogin = async (data) => {
    if (isSubmitting) return; // Eğer işlem zaten yapılıyorsa başka tıklamaları engelle
    setIsSubmitting(true);
    let body = {
      login: data.email,
      password: data.password,
      phone: formatPhoneNumber(data.email),
    };

    await axios
      .post(
        `https://api.medcheckup.az/api/auth/signin/?lang=${i18n.language}`,
        body
      )
      .then((res) => {
        setAuthToken(res.data.auth);
        setRefleshToken(res.data.refresh);
        setIsSubmitting(false);
        localStorage.setItem("token", JSON.stringify(res.data.auth));
        localStorage.setItem("refresh", JSON.stringify(res.data.refresh));
        setLoginVisible(false);
      })
      .catch((err) => {
        setErrorText(err.response.data.reason);
        setIsSubmitting(false);
      });
  };

  return (
    <aside className="login-modal" onClick={() => setLoginVisible(false)}>
      <div className="container">
        <div className="row">
          <form
            className="login-form-area scroll"
            onSubmit={handleSubmit(submitLogin)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="info-group">
              <h1 className="caption">Log in to existing account</h1>{" "}
              <div className="close" onClick={() => setLoginVisible(false)}>
                <img src={closeIcon} alt="close" />
              </div>
            </div>

            <div className="form-group">
              <label
                className={errors.email ? "label error" : "label"}
                htmlFor="email"
              >
                Email, phone number or username
              </label>
              <input
                className={errors.email ? "inp error" : "inp"}
                type="text"
                id="email"
                name="email"
                {...register("email", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>

            <div className="form-group">
              <div className="top">
                <label
                  className={errors.password ? "label error" : "label"}
                  htmlFor="password"
                >
                  Password
                </label>
                <div
                  className="icon"
                  onClick={() => setPassVisible(!passVisible)}
                >
                  <img src={passVisible ? hide : unHide} alt="unhide" />
                </div>
              </div>
              <input
                className={errors.password ? "inp error" : "inp"}
                type={passVisible ? "text" : "password"}
                id="password"
                name="password"
                {...register("password", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
              {errorText && (
                <div className="error-component">
                  <div className="info">
                    <div className="icon">
                      <img src={errorIcon} alt="error" />
                    </div>
                    <span className="text">{errorText}</span>
                  </div>
                </div>
              )}
            </div>
            <Link
              to="/forgot-password"
              className="forget-password"
              onClick={() => setLoginVisible(false)}
            >
              Fogote password
            </Link>

            <button className="submit">Login</button>
            <p className="login-adviser">
              Don’t have an account?{" "}
              <Link to="/sign-up" onClick={() => setLoginVisible(false)}>
                Sing up
              </Link>
            </p>
            <h2 className="alert-title">Other sing up accounts</h2>

            <div className="related-website">
              <div className="website">
                <span className="icon">
                  <img src={vaccine} alt="vaccine" />
                </span>{" "}
                Vaccine App
              </div>
              <Link
                to="https://videodoktor.az/"
                target="_blank"
                className="website"
              >
                <span className="icon">
                  <img src={videDoktor} alt="vaccine" />
                </span>
                Videodoctor
              </Link>
            </div>
          </form>
        </div>
      </div>
    </aside>
  );
};

export default Login;
