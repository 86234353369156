import React, { useCallback, useContext, useEffect, useState } from "react";
import ResultSymptom from "../../component/result/ResultSymptom";
import ResultGeneralInfo from "../../component/result/ResultGeneralInfo";
import AnswerInfo from "../../component/result/AnswerInfo";
import FeedBack from "../../component/result/FeedBack";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ResultPage = () => {
  // Global State
  const { token } = useContext(MainContext);

  const { i18n } = useTranslation();

  // Local State
  const [data, setData] = useState(null);

  const getResult = useCallback(async () => {
    await axios
      // .get(`https://api.medcheckup.az/api/diagnose/vaO69yvJTEmqQsTR/?lang=${i18n.language}`)
      .get(
        `https://api.medcheckup.az/api/diagnose/${token}/?lang=${i18n.language}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [i18n.language, token]);

  useEffect(() => {
    if (i18n.language) {
      getResult();
    }
  }, [getResult, i18n.language]);
  return (
    <>
      <ResultGeneralInfo />
      <ResultSymptom data={data} />
      <AnswerInfo data={data} />
      <FeedBack />
    </>
  );
};

export default ResultPage;
