import { useContext, useEffect, useState } from "react";
import { Auth } from "../../utils/Auth";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import Loader from "../../component/Loader";

const Account = () => {
  const { authToken, refleshToken, setAuthToken, setRefleshToken, logOut } =
    useContext(Auth);
  const { profileActivePage } = useContext(MainContext);

  // Local State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [succesMsj, setSuccesMsj] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Translate
  const { i18n } = useTranslation();

  const schema = object({
    fullname: string().required().trim(),
    birthday: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (profileActivePage === "Account") {
      setIsSubmitting(false);
      setSuccesMsj(false);
      setUserName("");
      setErrorText("");
      setLoading(true);
      axios
        .get(
          `https://api.medcheckup.az/api/user/profile/?token=${authToken}&lang=${i18n.language}`
        )
        .then((res) => {
          if (res.data.code === 103) {
            axios
              .post(`https://api.medcheckup.az/api/auth/refresh/?lang=en`, {
                refresh_token: refleshToken,
              })
              .then((res) => {
                setAuthToken(res.data.auth);
                setRefleshToken(res.data.refresh);
                setIsSubmitting(false);
              })
              .catch((err) => err && logOut());
          } else {
            setUserName(res.data.payload.name);
            setValue("fullname", res.data.payload.name);
            setValue("birthday", res.data.payload.birthdate);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    authToken,
    i18n.language,
    logOut,
    profileActivePage,
    refleshToken,
    setAuthToken,
    setRefleshToken,
    setValue,
  ]);

  const submitAccount = async (data) => {
    let body = {
      token: authToken,
      name: data.fullname,
      birthdate: data.birthdate,
    };

    if (isSubmitting) return;
    setIsSubmitting(true);

    await axios
      .post(
        `https://api.medcheckup.az/api/user/update-profile/?lang=${i18n.language}`,
        body
      )
      .then((res) => {
        // Update Personal Information
        if (res.data.status === "success") {
          setIsSubmitting(false);
          setSuccesMsj(true);
          setErrorText("Uğurla Dəyişildi");
          const timer = setTimeout(() => {
            setSuccesMsj(false);
            setErrorText(" ");
          }, 2000);
          return () => clearTimeout(timer);
        } else if (res.data.code === 104) {
          // Refresh Request
          axios
            .post(`https://api.medcheckup.az/api/auth/refresh/?lang=en`, {
              refresh_token: refleshToken,
            })
            .then((res) => {
              setAuthToken(res.data.auth);
              setRefleshToken(res.data.refresh);
              setIsSubmitting(false);
              submitAccount(data);
            })
            .catch((err) => {
              logOut();
              setIsSubmitting(false);
            });
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <section className="account-password">
        <h1 className="user-name">{userName}</h1>
        <form
          className="personality-information "
          onSubmit={handleSubmit(submitAccount)}
        >
          <div className="form-group">
            <label
              htmlFor="fullname"
              className={errors.fullname ? "label error" : "label"}
            >
              Full name
            </label>
            <input
              className={errors.fullname ? "inp error" : "inp"}
              type="text"
              id="fullname"
              name="fullname"
              {...register("fullname", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="birthday"
              className={errors.birthday ? "label error" : "label"}
            >
              Birthday
            </label>
            <input
              className={errors.birthday ? "inp error" : "inp"}
              type="date"
              id="birthday"
              name="birthday"
              {...register("birthday", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="button-area">
            {(errorText || succesMsj) && (
              <p className={succesMsj ? "error-alert succes" : "error-alert"}>
                {errorText}
              </p>
            )}
            <button>Save</button>
          </div>
        </form>
      </section>
    </>
  );
};

export default Account;
