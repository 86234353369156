/* -------------------------------- Component ------------------------------- */
import HomeCard from "../../component/HomeCard";
import HomeCaption from "./HomeCaption";


const MedCheckupInfo = ({ smptomCheckerData = {} ,smptomCheckerCards=[]}) => {
  const { title, description, learn_more_link } = smptomCheckerData;
  return (
    <section className="medCheckup-info">
      <div className="container">
        <div className="row">
          <HomeCaption
            title={title}
            detail={description}
            longText={true}
            link={learn_more_link}
          />
          <div className="info">
            {smptomCheckerCards.map((item) => (
              <HomeCard data={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MedCheckupInfo;
