import { useContext } from "react";
import back from "../assets/img/Icons/back-arrow.svg";
import { Link } from "react-router-dom";
import { MainContext } from "../utils/MainContext";

const NotFoundPage = () => {
  // Global State
  const { resetStates } = useContext(MainContext);
  return (
    <div className="not-found">
      <div className="pattern"></div>
      <div className="container">
        <div className="row">
          <div className="number">404</div>
          <Link
            className="back-btn"
            to="/"
            onClick={() => {
              resetStates();
            }}
          >
            <img src={back} alt="back" />
            Əsas səhifəya qayıt
          </Link>
        </div>
      </div>
      <div className="pattern"></div>
    </div>
  );
};

export default NotFoundPage;
