import { useContext } from "react";
import play from "../../assets/img/Icons/play.svg";
import HomeCaption from "./HomeCaption";
import { MainContext } from "../../utils/MainContext";
import WebsiteVideo from "./WebsiteVideo";

const WebsiteDescription = ({ videoData = {} }) => {
  // Global State
  const { videoVisible, setVideoVisible } = useContext(MainContext);

  const { title, description, video_url, video_poster } = videoData;

  return (
    <section className="website-description">
      <div className="container">
        <div className="row">
          <HomeCaption title={title} detail={description} />
          <div className="video-area" onClick={() => setVideoVisible(true)}>
            <div className="bg-img">
              <img src="" alt="" />
            </div>
            <div className="play-icon">
              <img src={play} alt="play-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className={videoVisible ? "video-modal visible" : "video-modal"}>
        <WebsiteVideo video_url={video_url} video_poster={video_poster} />
      </div>
    </section>
  );
};

export default WebsiteDescription;
