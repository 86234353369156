/* ---------------------------------- React --------------------------------- */
import React, { useCallback, useContext, useEffect, useState } from "react";

/* ------------------------------ React Slider ------------------------------ */
import ReactSlider from "react-slider";

/* --------------------------------- Context -------------------------------- */
import { MainContext } from "../../utils/MainContext";

/* -------------------------------- Component ------------------------------- */
import CheckupCaption from "./CheckupCaption";

/* ---------------------------------- Icon ---------------------------------- */
import negative from "../../assets/img/Icons/negative.svg";
import positive from "../../assets/img/Icons/positive.svg";

const Temperature = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    setCheckupPage,
    prevButtonRef,
    nextButtonRef,
    symptomList,
    setSymptomList,
    // |Function
    prevPageDirectroy,
    nextPageDirectroy,
  } = useContext(MainContext);

  /* ------------------------------- Local State ------------------------------ */
  const [renderData, setRenderData] = useState(null);
  const [value, setValue] = useState(0);
  // Lnaguage
  useEffect(() => {
    setRenderData(null);

    let specificObjData = symptomList.list[symptomList.currentInnerSymptom];
    if (specificObjData.widget_for_question === "temprature") {
      setRenderData(specificObjData);
    }
    if (specificObjData.userSelect) {
      setValue(Number(specificObjData.userSelect));
    } else {
      setValue(Number("35"));
    }
  }, [
    symptomList.currentInnerSymptom,
    symptomList.currentPageIndex,
    symptomList.list,
  ]);

  /* ------------------------ Decrement  &&  Increment ------------------------ */
  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };
  const handleIncrement = () => {
    if (value < Number("42")) {
      setValue(value + 1);
    }
  };

  const getClosestId = useCallback(
    (receivedNumber) => {
      if (renderData) {
        // Filter out items where ordering_number is null
        const validData = renderData.inner_symptoms.filter(
          (item) => item.ordering_number !== null
        );

        // Find exact match
        const exactMatch = validData.find(
          (item) => item.ordering_number === receivedNumber
        );
        if (exactMatch) {
          return exactMatch.id;
        }

        // If no exact match, find the closest ordering_number
        let closest = validData.reduce((prev, curr) => {
          return Math.abs(curr.ordering_number - receivedNumber) <
            Math.abs(prev.ordering_number - receivedNumber)
            ? curr
            : prev;
        }, validData[0]);

        // If no valid data, return 0
        if (!validData.length) {
          return 0;
        }

        return closest.id;
      }
    },
    [renderData]
  );

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      prevPageDirectroy(symptomList.currentInnerSymptom - 1);
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    prevButtonRef,
    prevPageDirectroy,
    setCheckupPage,
    symptomList.currentInnerSymptom,
  ]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      const selectId = getClosestId(Number(value));

      let generalDatas = symptomList.list;
      generalDatas[symptomList.currentInnerSymptom] = {
        ...generalDatas[symptomList.currentInnerSymptom], // Mevcut objeyi koru
        select: selectId,
        userSelect: value, // Sadece 'inner' key'ini değiştir
      };

      let specificObjData = symptomList.list[symptomList.currentInnerSymptom];
      setSymptomList((prev) => ({
        ...prev,
        list: prev.list.map((item) =>
          item.id === specificObjData.id
            ? { ...item, select: selectId, userSelect: value }
            : item
        ),
      }));

      // Next Page
      nextPageDirectroy(symptomList.currentInnerSymptom + 1, generalDatas);
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    getClosestId,
    nextButtonRef,
    nextPageDirectroy,
    setSymptomList,
    symptomList.currentInnerSymptom,
    symptomList.list,
    value,
  ]);
  return (
    <div className="specific-info" style={{ minHeight: "calc(100vh - 300px)" }}>
      {renderData && (
        <div className="body-info">
          <CheckupCaption
            title={renderData.question_for_inner_symptoms}
            className={"static"}
          />
          <div className="row">
            <button className="decrement" onClick={handleDecrement}>
              <img src={negative} alt="-" />
            </button>
            <div className="slider">
              <ReactSlider
                ariaLabelledby="slider-label"
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                max={Number("42")}
                min={Number("33")}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                value={value}
                onChange={(newValue) => setValue(newValue)}
              />
            </div>
            <button className="increment" onClick={handleIncrement}>
              <img src={positive} alt="+" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Temperature;
