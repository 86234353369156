import React from "react";
import Button from "../../component/Button";
import { useTranslation } from "react-i18next";

const Hero = ({ heroData = {} }) => {
  // Language
  const { t } = useTranslation();
  const { title, subtitle } = heroData;
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="info">
            <h1 className="title">{title}</h1>
            <p className="detail">{subtitle}</p>
            <Button text={t("hero.start_checkup")} link={"/check-up"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
